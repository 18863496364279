import type { NextPage } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";
import { PageLayout } from "src/components";
import withAuth from "src/components/withAuth";
const Invites = dynamic(() => import("src/views/Invites"), {
  ssr: false
});
const InvitesPage: NextPage = () => {
  return <>
      <Head>
        <title>Create vID Invite | Cerebrum Cognition</title>
        <meta name="description" content="Create an invite to vID" />
      </Head>
      <main>
        <PageLayout>
          <Invites />
        </PageLayout>
      </main>
    </>;
};
export default withAuth(InvitesPage);